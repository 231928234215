import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import { usePinCodeRouteComponent } from './composables';
import PinInput from '../../components/PinInput/PinInput.vue';
import PinButtons from '../../components/PinButtons/PinButtons.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PinCodeRouteComponent',
    props: {
        enableTweakError: {
            type: Boolean
        },
        errorMessage: {
            default: ''
        },
        firstCode: {},
        isLoading: {
            type: Boolean
        },
        showForgot: {
            type: Boolean,
            default: true
        }
    },
    emits: [
        "on-change",
        "on-forgot",
        "on-submit",
        "reset"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isVisible, pinInputProperties, handleClick, onForgot, resetPin } = usePinCodeRouteComponent(props, emits);
        __expose({
            resetPin
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['pin-code-route-component'])
            }, [
                _createVNode(PinInput, _normalizeProps(_guardReactiveProps(_unref(pinInputProperties))), null, 16),
                _createVNode(PinButtons, {
                    class: _normalizeClass(_ctx.$style['pin-code-route-component__buttons']),
                    visible: _unref(isVisible),
                    onClick: _unref(handleClick)
                }, null, 8, [
                    "class",
                    "visible",
                    "onClick"
                ]),
                _ctx.showForgot ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['pin-code-route-component__forgot'])
                }, [
                    _createElementVNode("button", {
                        type: "button",
                        class: _normalizeClass(_ctx.$style['pin-code-route-component__forgot-link']),
                        onClick: _cache[0] || (_cache[0] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onForgot) && _unref(onForgot)(...args);
                        })
                    }, _toDisplayString(_ctx.$t('WEB2_PIN_CODE_FORGOT')), 3)
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'pin-code-route-component__forgot'
                        }
                    ]
                ]) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PinCodeRouteComponent'
                ]
            ]);
        };
    }
});
