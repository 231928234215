import { computed, onUnmounted, provide, ref } from 'vue';
import { PinButtonEvent } from '../../../enums';
import useI18n from '../../../../i18n/composables/useI18n';
export default function usePinCodeRouteComponent(props, emits) {
    const { $translate } = useI18n();
    const COUNT = 6;
    const pinCode = ref('');
    const isVisible = ref(false);
    provide('resetPin', resetPin);
    const getError = computed(()=>{
        const arr = [];
        if (props.errorMessage) return props.errorMessage;
        if (pinCode.value.length !== COUNT) return '';
        if (props.firstCode && props.firstCode !== pinCode.value) return $translate('WEB2_PIN_CODE_DO_NOT_MATCH').value;
        for (const i of pinCode.value.split(''))if (!arr.includes(i)) arr.push(i);
        if (1 === arr.length) return props.enableTweakError ? $translate('WEB2_PIN_CODE_ERROR_NOT_UNIQUE').value : $translate('WEB2_INVALID_PIN_CODE').value;
        return '';
    });
    const pinInputProperties = computed(()=>({
            pin: pinCode.value,
            error: getError.value,
            isVisible: isVisible.value,
            isLoading: props.isLoading
        }));
    function onChange() {
        emits('on-change', pinCode.value);
    }
    function onSubmit() {
        emits('on-submit', pinCode.value);
    }
    function onForgot() {
        emits('on-forgot', pinCode.value);
    }
    function resetPin() {
        emits('reset');
        pinCode.value = '';
    }
    function handleClick(value) {
        switch(value){
            case PinButtonEvent.VISIBILITY:
                isVisible.value = !isVisible.value;
                break;
            case PinButtonEvent.BACKSPACE:
                if (pinCode.value.length > 0) {
                    pinCode.value = pinCode.value.slice(0, -1);
                    onChange();
                }
                break;
            default:
                if (getError.value) {
                    pinCode.value = value;
                    onChange();
                } else if (pinCode.value.length < COUNT) {
                    pinCode.value = `${pinCode.value}${value}`;
                    onChange();
                }
        }
        if (!getError.value && pinCode.value.length === COUNT) onSubmit();
    }
    onUnmounted(()=>{
        pinCode.value = '';
    });
    return {
        isVisible,
        pinInputProperties,
        handleClick,
        onChange,
        onForgot,
        onSubmit,
        resetPin
    };
}
