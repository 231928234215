import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { onMounted, onUnmounted } from 'vue';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import { usePinCountDown } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PinCountDown',
    props: {
        wrongAttemptsLimit: {},
        bannedUntil: {}
    },
    emits: [
        "click",
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { jumbotronProperties, time, actionButtonProperties, closeButtonProperties, onComponentMounted, onComponentUnMounted, onClick, onClose } = usePinCountDown(props, emits);
        onMounted(onComponentMounted);
        onUnmounted(onComponentUnMounted);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VJumbotron), _mergeProps({
                class: _ctx.$style['pin-count-down__jumbotron']
            }, _unref(jumbotronProperties)), {
                footer: _withCtx(()=>[
                        _createVNode(_unref(JumbotronFooterButton), _mergeProps(_unref(actionButtonProperties), {
                            onClick: _unref(onClick)
                        }), null, 16, [
                            "onClick"
                        ]),
                        _createVNode(_unref(JumbotronFooterButton), _mergeProps(_unref(closeButtonProperties), {
                            onClick: _unref(onClose)
                        }), null, 16, [
                            "onClick"
                        ])
                    ]),
                default: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['pin-count-down__time'])
                        }, _toDisplayString(_unref(time)), 3)
                    ]),
                _: 1
            }, 16, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'PinCountDown'
                ]
            ]);
        };
    }
});
