import { computed, ref } from 'vue';
import { AlertIconName, IconName, IconSize } from '@leon-hub/icons';
import { Timer } from '@leon-hub/utils';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { getJumbotronProps, JumbotronIconKind } from 'web/src/components/Jumbotron';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function usePinCountDown(props, emits) {
    const { $translate } = useI18n();
    const countDown = ref(0);
    const time = ref('');
    let interval = 0;
    const ONE_SECOND = 1000;
    const headingBtrParams = computed(()=>({
            count: props.wrongAttemptsLimit || ''
        }));
    const jumbotronProperties = computed(()=>({
            ...getJumbotronProps({
                iconSize: IconSize.SIZE_50,
                iconKind: JumbotronIconKind.ALERT,
                alertIcon: AlertIconName.Alert,
                icon: IconName.ATTENTION_OUTLINE
            }),
            heading: $translate('WEB2_PIN_CODE_ERROR_COUNT', headingBtrParams).value,
            text: countDown.value ? $translate('WEB2_PIN_CODE_RESET_OR_RETRY_WITH_COUNTDOWN').value : $translate('WEB2_PIN_CODE_RESET_OR_RETRY').value
        }));
    const actionButtonProperties = computed(()=>({
            kind: ButtonKind.PRIMARY,
            fullWidth: true,
            label: countDown.value ? $translate('WEB2_PIN_CODE_RESET').value : $translate('WEB2_PIN_CODE_ENTER').value
        }));
    const closeButtonProperties = computed(()=>({
            kind: ButtonKind.TRANSPARENT,
            fullWidth: true,
            label: countDown.value ? $translate('WEB2_CLOSE').value : $translate('WEB2_PIN_CODE_RESET').value
        }));
    function updateTime() {
        if (0 === countDown.value) time.value = '';
        else {
            const date = new Date(countDown.value * ONE_SECOND);
            const timeNew = date.toTimeString().split(' ')[0].split(':');
            time.value = `${timeNew[1]}:${timeNew[2]}`;
        }
    }
    function startCountDown() {
        if (countDown.value) {
            countDown.value -= 1;
            updateTime();
        }
    }
    function onClick() {
        countDown.value ? emits('click', 'RESET_PIN_CODE') : emits('click', 'ENTER_PIN_CODE');
    }
    function onClose() {
        countDown.value ? emits('close') : emits('click', 'RESET_PIN_CODE');
    }
    function onComponentMounted() {
        const date = new Date().getTime();
        countDown.value = Math.ceil((props.bannedUntil - date) / ONE_SECOND);
        updateTime();
        interval = Timer.setInterval(()=>startCountDown(), ONE_SECOND);
    }
    function onComponentUnMounted() {
        if (interval) {
            Timer.clearInterval(interval);
            interval = 0;
        }
    }
    return {
        jumbotronProperties,
        time,
        actionButtonProperties,
        closeButtonProperties,
        onClick,
        onClose,
        onComponentMounted,
        onComponentUnMounted
    };
}
