import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
import { usePinInput } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PinInput',
    props: {
        pin: {
            default: ''
        },
        error: {},
        isVisible: {
            type: Boolean
        },
        isLoading: {
            type: Boolean
        },
        count: {
            default: 6
        }
    },
    setup (__props) {
        const props = __props;
        const { getValue } = usePinInput(props);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['pin-input'])
            }, [
                _withDirectives((_openBlock(), _createElementBlock("ul", {
                    class: _normalizeClass({
                        [_ctx.$style['pin-input__wrapper']]: true,
                        [_ctx.$style['pin-input__wrapper--loading']]: _ctx.isLoading
                    })
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.count, (index)=>_withDirectives((_openBlock(), _createElementBlock("li", {
                            key: index,
                            class: _normalizeClass({
                                [_ctx.$style['pin-input__item']]: true,
                                [_ctx.$style['pin-input__item--error']]: _ctx.error,
                                [_ctx.$style['pin-input__item--filled']]: !!_ctx.pin[index - 1]
                            })
                        }, [
                            _ctx.isVisible && !_ctx.isLoading ? _withDirectives((_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['pin-input__item-inner--visible'])
                            }, [
                                _createElementVNode("span", {
                                    class: _normalizeClass({
                                        [_ctx.$style['pin-input__item-inner-dot']]: void 0 === props.pin[index - 1]
                                    })
                                }, _toDisplayString(_unref(getValue)(index)), 3)
                            ], 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'pin-input__item-inner--visible'
                                    }
                                ]
                            ]) : _withDirectives((_openBlock(), _createElementBlock("span", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['pin-input__item-inner--hidden'])
                            }, null, 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'pin-input__item-inner--hidden'
                                    }
                                ]
                            ])
                        ], 2)), [
                            [
                                _directive_data_test,
                                {
                                    el: 'pin-input__item'
                                }
                            ]
                        ])), 128))
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'pin-input__wrapper'
                        }
                    ]
                ]),
                _ctx.error ? _withDirectives((_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['pin-input__error'])
                }, [
                    _createTextVNode(_toDisplayString(_ctx.error), 1)
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'pin-input__error'
                        }
                    ]
                ]) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PinInput'
                ]
            ]);
        };
    }
});
