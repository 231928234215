import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { onBeforeUnmount } from 'vue';
import { VLoader } from '@components/loader';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VForm from 'web/src/components/Form/components/VForm/VForm.vue';
import RestoreContainer from 'web/src/modules/restore-password/components/RestoreContainer/RestoreContainer.vue';
import { useRestorePinMainRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RestorePinMainRoutePage',
    props: {
        isInProfile: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { captchaForm, uiSchema, jsonSchema, isEmailRestore, isMainFormReady, isSubmitPending, isRestoreByPhoneSent, isRestoreByEmailSent, onInput, onSubmit, beforeUnmount, sendUidWrapped, sendSmsWrapped, resendRestorePasswordLinkToEmail, setIsRestoreByPhoneSent } = useRestorePinMainRoutePage(props);
        onBeforeUnmount(beforeUnmount);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(RestoreContainer, {
                "is-restore-by-phone-sent": _unref(isRestoreByPhoneSent),
                "is-restore-by-email-sent": _unref(isRestoreByEmailSent),
                "is-in-profile": _ctx.isInProfile,
                "is-pin": "",
                onResendEmailSuccess: _unref(resendRestorePasswordLinkToEmail),
                onCheckPinSuccess: _unref(setIsRestoreByPhoneSent)
            }, {
                default: _withCtx(()=>[
                        _unref(isMainFormReady) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createElementVNode("p", {
                                class: _normalizeClass(_ctx.$style['restore__label'])
                            }, _toDisplayString(_unref(isEmailRestore) ? _ctx.$t('WEB2_RESTORE_PIN_BYEMAIL_HINT') : _ctx.$t('WEB2_RESTORE_PIN_BYPHONE_HINT')), 3),
                            _createVNode(VForm, {
                                ref_key: "captchaForm",
                                ref: captchaForm,
                                schema: _unref(jsonSchema),
                                "ui-schema": _unref(uiSchema),
                                "is-pending": _unref(isSubmitPending),
                                onSubmit: _cache[0] || (_cache[0] = ($event)=>_unref(isEmailRestore) ? _unref(sendUidWrapped)() : _unref(sendSmsWrapped)()),
                                onInput: _unref(onInput)
                            }, null, 8, [
                                "schema",
                                "ui-schema",
                                "is-pending",
                                "onInput"
                            ]),
                            _createVNode(VButton, {
                                "full-width": "",
                                onClick: _unref(onSubmit)
                            }, {
                                default: _withCtx(()=>[
                                        _createTextVNode(_toDisplayString(_ctx.$t('WEB2_NEXT')), 1)
                                    ]),
                                _: 1
                            }, 8, [
                                "onClick"
                            ])
                        ], 64)) : (_openBlock(), _createBlock(_unref(VLoader), {
                            key: 0
                        }))
                    ]),
                _: 1
            }, 8, [
                "is-restore-by-phone-sent",
                "is-restore-by-email-sent",
                "is-in-profile",
                "onResendEmailSuccess",
                "onCheckPinSuccess"
            ])), [
                [
                    _directive_auto_id,
                    'RestorePinMainRoutePage'
                ]
            ]);
        };
    }
});
