import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
import GqlApiCaptchaRequiredError from '@leon-hub/api/src/client/graphql/errors/GqlApiCaptchaRequiredError';
import { LoginType, PhoneCodeMethod } from '@leon-hub/api-sdk';
import { CaptchaRequesterStrategy } from '@leon-hub/module-captcha';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { RestorePasswordFieldName } from 'web/src/modules/restore-password/enums';
import { useAuthStore } from 'web/src/modules/auth/store';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { PresetName } from 'web/src/modules/dialogs/enums';
import { useFormsStore } from 'web/src/modules/forms/store';
import { FormControlType } from 'web/src/components/Form/enums';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useRestorePasswordStore } from 'web/src/modules/restore-password/store';
import { useErrorHandler } from 'web/src/modules/core/composables/errors';
import useCaptchaResolver from 'web/src/modules/captcha/composables/useCaptchaResolver';
export default function useRestorePinMainRoutePage(props) {
    const { showDialog } = useDialogs();
    const restorePasswordStore = useRestorePasswordStore();
    const authStore = useAuthStore();
    const formsStore = useFormsStore();
    const pinCodeStore = usePinCodeStore();
    const { isLoggedIn } = useIsLoggedIn();
    const { handleError } = useErrorHandler(useRouter());
    const { setCaptchaToken, resolveCaptcha } = useCaptchaResolver();
    const isRestoreByEmailSent = toRef(pinCodeStore, 'isRestoreByEmailSent');
    const isRestoreByPhoneSent = toRef(pinCodeStore, 'isRestoreByPhoneSent');
    const isMainFormReady = toRef(restorePasswordStore, 'isMainFormReady');
    const captchaSettings = toRef(restorePasswordStore, 'captchaSettings');
    const captchaForm = ref();
    const isSubmitPending = ref(false);
    const isEmailRestore = computed(()=>restorePasswordStore.activeTab === LoginType.EMAIL);
    const jsonSchema = computed(()=>formsStore.restoreBySendLinkToEmail ?? {
            properties: {}
        });
    const uiSchema = computed(()=>({
            order: [
                RestorePasswordFieldName.CAPTCHA_TOKEN
            ],
            submitButton: null,
            fields: {
                [RestorePasswordFieldName.CAPTCHA_TOKEN]: {
                    hidden: true,
                    widget: FormControlType.Captcha,
                    options: {
                        captchaRequesterStrategy: CaptchaRequesterStrategy.RESTORE_PASSWORD,
                        captchaEnabled: captchaSettings.value.isEnabledToShow,
                        reCaptchaTheme: captchaSettings.value.theme,
                        isEnforced: captchaSettings.value.isEnforced
                    }
                }
            }
        }));
    function onInput(payload) {
        const captchaToken = payload.formData[RestorePasswordFieldName.CAPTCHA_TOKEN];
        if (captchaToken) setCaptchaToken(captchaToken);
    }
    async function handleCaptcha(forcedCaptchaType) {
        const captchaType = forcedCaptchaType ?? captchaSettings.value.captchaType;
        return resolveCaptcha(captchaType);
    }
    function sendUid() {
        isSubmitPending.value = true;
        pinCodeStore.handleSendRestorePinLinkToEmail().catch(async (error)=>{
            if (error instanceof GqlApiCaptchaRequiredError) {
                await handleCaptcha(error.extensions.captchaType);
                sendUid();
                return;
            }
            showErrorDialog(error);
        }).finally(()=>{
            pinCodeStore.setIsRestoreByEmailSent(true);
            isSubmitPending.value = false;
        });
    }
    async function sendUidWrapped() {
        if (captchaSettings.value.isEnforced && !isLoggedIn.value) await handleCaptcha();
        sendUid();
    }
    function showErrorDialog(error) {
        showDialog({
            presetName: PresetName.ALERT_ERROR,
            options: {
                confirmMessage: error?.message,
                dataTestId: 'restore-pin-error'
            }
        }).promise.then(()=>pinCodeStore.setIsRestoreByEmailSent(false));
    }
    function resendRestorePasswordLinkToEmail(param) {
        let { onSuccess } = param;
        pinCodeStore.handleSendRestorePinLinkToEmail().then(onSuccess).catch(async (error)=>{
            if (error instanceof GqlApiCaptchaRequiredError) {
                await handleCaptcha();
                resendRestorePasswordLinkToEmail({
                    onSuccess
                });
                return;
            }
            showErrorDialog(error);
        });
    }
    async function sendSms() {
        try {
            isSubmitPending.value = true;
            await pinCodeStore.handleSendRestorePinTokenPhone(PhoneCodeMethod.SMS);
            restorePasswordStore.setPhoneCodeMethod(PhoneCodeMethod.SMS);
            restorePasswordStore.setRetrySmsCodeTime(restorePasswordStore.smsResendCodeTimer);
        } catch (originalError) {
            if (originalError instanceof GqlApiCaptchaRequiredError) {
                await handleCaptcha(originalError.extensions.captchaType);
                await sendSms();
                return;
            }
            throw originalError;
        } finally{
            pinCodeStore.setIsRestoreByPhoneSent(true);
            isSubmitPending.value = false;
        }
    }
    async function sendSmsWrapped() {
        if (captchaSettings.value.isEnforced && !isLoggedIn.value) await handleCaptcha();
        await sendSms();
    }
    function setIsRestoreByPhoneSent() {
        pinCodeStore.setIsRestoreByPhoneSent(false);
    }
    function onSubmit() {
        if (isLoggedIn.value) {
            if (isEmailRestore.value) sendUidWrapped();
            else sendSmsWrapped();
        } else captchaForm.value?.submit();
    }
    function beforeUnmount() {
        pinCodeStore.setIsRestoreByEmailSent(false);
        pinCodeStore.setIsRestoreByPhoneSent(false);
        if (!props.isInProfile) {
            authStore.setIsTemporarySession(false);
            authStore.setLoggedIn(false);
        }
    }
    useBusSafeSubscribe(BusEvent.CAPTCHA_SERVICE_ERROR, (data)=>{
        isSubmitPending.value = false;
        handleError(data.error);
    });
    useBusSafeSubscribe(BusEvent.CAPTCHA_CHALLENGE_IS_CLOSED, ()=>{
        isSubmitPending.value = false;
    });
    return {
        captchaForm,
        uiSchema,
        jsonSchema,
        isEmailRestore,
        isMainFormReady,
        isSubmitPending,
        isRestoreByPhoneSent,
        isRestoreByEmailSent,
        onInput,
        onSubmit,
        beforeUnmount,
        sendUidWrapped,
        sendSmsWrapped,
        resendRestorePasswordLinkToEmail,
        setIsRestoreByPhoneSent
    };
}
