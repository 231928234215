import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { onBeforeUnmount, onMounted } from 'vue';
import RegistrationCompletionRouteComponent from // eslint-disable-next-line max-len
'web/src/modules/registration-completion/pages/RegistrationCompletionRoutePage/RegistrationCompletionRoutePage.vue';
import { CustomerCompletionAction } from 'web/src/modules/registration-completion/enums';
import PinCountDown from '../PinCountDown/PinCountDown.vue';
import PinCodeRouteComponent from '../../layouts/PinCodeRouteComponent/PinCodeRouteComponent.vue';
import { usePinCodeController } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PinCodeController',
    setup (__props) {
        const { bannedUntil, createPinFirstCode, enableTweakError, error, loading, pinCodeWrongAttemptsLimit, showCompleteRegistrationFields, step, pinCode, closeCompleteRegistrationFields, handleCloseWithCleanData, onChange, onComponentCreated, onComponentUnmounted, onCountDownClick, onForgot, onSubmit } = usePinCodeController();
        onMounted(()=>{
            onComponentCreated();
        });
        onBeforeUnmount(()=>{
            onComponentUnmounted();
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['pin-code-controller']]: true,
                    [_ctx.$style['pin-code-controller--centered']]: void 0
                })
            }, [
                _unref(showCompleteRegistrationFields) && 'MODAL_CREATE' !== _unref(step) ? (_openBlock(), _createBlock(RegistrationCompletionRouteComponent, {
                    key: 0,
                    "manual-customer-action": _unref(CustomerCompletionAction).CreatePinCode,
                    description: _ctx.$t('WEB2_REGISTRATION_COMPLETION_PINCODE_DESCRIPTION'),
                    onAfterSubmit: _unref(closeCompleteRegistrationFields)
                }, null, 8, [
                    "manual-customer-action",
                    "description",
                    "onAfterSubmit"
                ])) : 'COUNTDOWN' === _unref(step) ? (_openBlock(), _createBlock(PinCountDown, {
                    key: 1,
                    "banned-until": _unref(bannedUntil),
                    "wrong-attempts-limit": _unref(pinCodeWrongAttemptsLimit).toString(),
                    onClick: _unref(onCountDownClick),
                    onClose: _unref(handleCloseWithCleanData)
                }, null, 8, [
                    "banned-until",
                    "wrong-attempts-limit",
                    "onClick",
                    "onClose"
                ])) : (_openBlock(), _createBlock(PinCodeRouteComponent, {
                    key: 2,
                    ref_key: "pinCode",
                    ref: pinCode,
                    "is-loading": _unref(loading),
                    "error-message": _unref(error),
                    "show-forgot": 'CREATE' !== _unref(step) && 'CREATE_CONFIRM' !== _unref(step),
                    "enable-tweak-error": _unref(enableTweakError),
                    "first-code": _unref(createPinFirstCode),
                    onOnSubmit: _unref(onSubmit),
                    onOnChange: _unref(onChange),
                    onOnForgot: _unref(onForgot)
                }, null, 8, [
                    "is-loading",
                    "error-message",
                    "show-forgot",
                    "enable-tweak-error",
                    "first-code",
                    "onOnSubmit",
                    "onOnChange",
                    "onOnForgot"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'PinCodeController'
                ]
            ]);
        };
    }
});
