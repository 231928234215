import { computed, ref, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import { AccessDeniedRemoteApiExceptionCode } from '@leon-hub/api-sdk';
import { GqlApiAccessDeniedError } from '@leon-hub/api';
import { normalizeError } from '@leon-hub/errors';
import { AlertIconName, IconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { useAuthStore } from 'web/src/modules/auth/store';
import { useRouterStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getImageOrIcon } from 'web/src/modules/icons';
import useWithdrawalStore from 'web/src/modules/payments/submodules/withdrawals/store/useWithdrawalStore';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { useResponsibleGamblingStore } from 'web/src/modules/responsible-gambling/store';
import { CustomerCompletionAction } from 'web/src/modules/registration-completion/enums';
import { useRegistrationCompletionStore } from 'web/src/modules/registration-completion/store';
export default function usePinCodeController() {
    const router = useRouter();
    const routerStore = useRouterStore();
    const { $translate } = useI18n();
    const { showDialog, closeDialog } = useDialogs();
    const { convertToBaseError } = useErrorsConverter();
    const siteConfigStore = useSiteConfigStore();
    const pinCodeStore = usePinCodeStore();
    const { setLoggedIn, setIsTemporarySession } = useAuthStore();
    const { setDepositLimitsData, setSelfExclusionId } = useResponsibleGamblingStore();
    const withdrawalStore = useWithdrawalStore();
    const { fetchIncompleteRegistrationFields } = useRegistrationCompletionStore();
    const pinCode = ref();
    const DISABLED_TWEAK_STEPS = [
        'LOGIN',
        'VERIFY',
        'UPDATE_PREV',
        'CREATE_CONFIRM',
        'DELETE'
    ];
    const createPinFirstCode = ref('');
    const isReset = ref(false);
    const blockedOperation = ref();
    const modalId = ref('');
    const { createPinCode, setStepWithLS, setBannedUntil, setShowCompleteRegistrationFields, setCode, setResetErrorMessage, resetPinCodeByPhone, resetPinCodeToken, setRouteFrom, verifyPinCode, loginPinCode, setPinCodeRequestedAtLogin, deletePinCode, setError, setStepCountdown } = usePinCodeStore();
    const loading = toRef(pinCodeStore, 'loading');
    const error = toRef(pinCodeStore, 'error');
    const prevStep = toRef(pinCodeStore, 'prevStep');
    const step = toRef(pinCodeStore, 'step');
    const pinCodeModalCounter = toRef(pinCodeStore, 'pinCodeModalCounter');
    const bannedUntil = toRef(pinCodeStore, 'bannedUntil');
    const routeFrom = toRef(pinCodeStore, 'routeFrom');
    const showCompleteRegistrationFields = toRef(pinCodeStore, 'showCompleteRegistrationFields');
    const code = toRef(pinCodeStore, 'code');
    const resetErrorMessage = toRef(pinCodeStore, 'resetErrorMessage');
    const resetToken = toRef(pinCodeStore, 'resetToken');
    const isPhoneReset = toRef(pinCodeStore, 'isPhoneReset');
    const currentRouteName = toRef(routerStore, 'currentRouteName');
    const pinCodeWrongAttemptsLimit = toRef(siteConfigStore, 'pinCodeWrongAttemptsLimit');
    const pinCodeModalShowLimit = toRef(siteConfigStore, 'pinCodeModalShowLimit');
    const restoreRoute = computed(()=>routeFrom.value && 'LOGIN' !== prevStep.value ? RouteName.RESTORE_PIN_PROFILE : RouteName.RESTORE_PIN);
    const enableTweakError = computed(()=>!DISABLED_TWEAK_STEPS.includes(step.value ?? ''));
    const isStatusIgnored = toRef(pinCodeStore, 'isStatusIgnored');
    function checkBannedUntil() {
        if (!bannedUntil.value) return false;
        return bannedUntil.value > new Date().getTime();
    }
    async function resetPinCode() {
        const isGoingToReset = !!resetToken.value;
        if (isGoingToReset) try {
            if (isPhoneReset.value) await resetPinCodeByPhone(resetToken.value);
            resetPinCodeToken({
                value: '',
                phoneReset: false
            });
            isReset.value = true;
        } catch (rawError) {
            if (rawError instanceof GqlApiAccessDeniedError) {
                router.push({
                    name: RouteName.LOGIN
                });
                return false;
            }
            const originalError = normalizeError(rawError);
            setResetErrorMessage(originalError.message);
            setStepWithLS('TOKEN_EXPIRED');
            return false;
        }
        return true;
    }
    function handleClose() {
        isReset.value = false;
        setStepWithLS(null);
        setShowCompleteRegistrationFields(false);
        if (routeFrom.value) {
            router.replace(routeFrom.value);
            setRouteFrom(null);
        } else if (currentRouteName.value === RouteName.PIN_CODE) router.closeModal();
    }
    function handleCloseWithCleanData() {
        setDepositLimitsData(null);
        setSelfExclusionId(null);
        withdrawalStore.setSubmitData(null);
        handleClose();
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function preHandleStep(stepName) {
        if ('RESET_AFTER_LOGIN' === stepName) resetPinCode().then(async (isPinCodeReset)=>{
            if (!isPinCodeReset) return;
            await createPinCode(code.value);
            createPinFirstCode.value = '';
            setStepWithLS('MODAL_CREATE_SUCCESS');
        });
        else if ('CREATE' === stepName) {
            setBannedUntil(0);
            "1";
            {
                const result = await fetchIncompleteRegistrationFields({
                    customerAction: CustomerCompletionAction.CreatePinCode
                });
                if (result) setShowCompleteRegistrationFields(true);
            }
        } else if (checkBannedUntil() && !isStatusIgnored.value) {
            if ('COUNTDOWN' === stepName) blockedOperation.value = prevStep.value;
            else {
                setStepCountdown();
                return false;
            }
        } else if (bannedUntil.value && !isStatusIgnored.value) setBannedUntil(0);
        return true;
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function handleStep() {
        if (!step.value) {
            createPinFirstCode.value = '';
            return;
        }
        const isReadyToHandle = await preHandleStep(step.value);
        if (!isReadyToHandle) return;
        let modalProps = null;
        switch(step.value){
            case 'MODAL_CREATE':
                modalProps = {
                    presetName: PresetName.CONFIRM,
                    options: {
                        ...getImageOrIcon({
                            icon: IconName.SHIELD_LOCK,
                            alertIcon: AlertIconName.Lock
                        }),
                        width: ModalWidth.SMALL,
                        title: $translate('WEB2_SET_PIN_CODE_TITLE').value,
                        confirmMessage: $translate('WEB2_SET_PIN_CODE_DESCRIPTION').value,
                        buttons: [
                            {
                                label: $translate('WEB2_SET_PIN_CODE').value
                            },
                            {
                                label: pinCodeModalCounter.value === pinCodeModalShowLimit.value - 1 ? $translate('WEB2_DONT_SHOW_AGAIN').value : $translate('WEB2_SET_PIN_CODE_LATER').value
                            }
                        ]
                    }
                };
                break;
            case 'MODAL_CREATE_SUCCESS':
                modalProps = {
                    presetName: PresetName.ALERT_SUCCESS,
                    options: {
                        ...getImageOrIcon({
                            alertIcon: AlertIconName.Success
                        }),
                        title: isReset.value ? $translate('WEB2_PIN_CODE_IS_CHANGED_TITLE').value : $translate('WEB2_PIN_CODE_IS_SET_TITLE').value,
                        confirmMessage: $translate('WEB2_PIN_CODE_IS_SET_DESCRIPTION').value,
                        fullHeight: true,
                        isFullHeightCentered: false
                    }
                };
                break;
            case 'MODAL_UPDATE_SUCCESS':
                modalProps = {
                    presetName: PresetName.ALERT_SUCCESS,
                    options: {
                        ...getImageOrIcon({
                            alertIcon: AlertIconName.Success
                        }),
                        title: $translate('WEB2_PIN_CODE_IS_CHANGED_TITLE').value,
                        confirmMessage: $translate('WEB2_PIN_CODE_IS_CHANGED_DESCRIPTION').value,
                        fullHeight: true,
                        isFullHeightCentered: false
                    }
                };
                break;
            case 'SUCCESS_VERIFY':
                handleClose();
                break;
            case 'TOKEN_EXPIRED':
                modalProps = {
                    presetName: PresetName.ALERT_WARNING,
                    options: {
                        ...getImageOrIcon({
                            alertIcon: AlertIconName.Alert
                        }),
                        topBarText: $translate('WEB2_PIN_RESTORE_TITLE').value,
                        confirmMessage: resetErrorMessage.value || $translate('WEB2_DEVICE_RECOVER_OLD_LINK').value,
                        fullHeight: false,
                        isFullHeightCentered: true
                    }
                };
                break;
            default:
                break;
        }
        if (modalProps) {
            const { id, subscribe } = showDialog(modalProps);
            subscribe({
                [DialogAction.CONFIRM]: ()=>{
                    if ('MODAL_CREATE' === step.value) setStepWithLS('CREATE');
                },
                [DialogAction.MODAL_CLOSE]: ()=>{
                    if ('TOKEN_EXPIRED' === step.value) resetPinCodeToken({
                        value: ''
                    });
                    setCode('');
                    handleClose();
                }
            });
            modalId.value = id;
        }
    }
    async function onSubmit(pinCodeString) {
        try {
            setCode(pinCodeString);
            switch(step.value){
                case 'CREATE':
                    createPinFirstCode.value = pinCodeString;
                    setStepWithLS('CREATE_CONFIRM');
                    pinCode.value?.resetPin();
                    break;
                case 'CREATE_CONFIRM':
                    resetPinCode().then(async (isPinCodeReset)=>{
                        if (!isPinCodeReset) return;
                        await createPinCode(pinCodeString);
                        createPinFirstCode.value = '';
                        setStepWithLS('MODAL_CREATE_SUCCESS');
                    });
                    break;
                case 'VERIFY':
                    await verifyPinCode(pinCodeString);
                    setStepWithLS('SUCCESS_VERIFY');
                    break;
                case 'UPDATE_PREV':
                    await deletePinCode(pinCodeString);
                    setStepWithLS('UPDATE');
                    pinCode.value?.resetPin();
                    break;
                case 'UPDATE':
                    createPinFirstCode.value = pinCodeString;
                    setStepWithLS('UPDATE_CONFIRM');
                    pinCode.value?.resetPin();
                    break;
                case 'UPDATE_CONFIRM':
                    await createPinCode(pinCodeString);
                    createPinFirstCode.value = '';
                    setStepWithLS('MODAL_UPDATE_SUCCESS');
                    break;
                case 'DELETE':
                    await deletePinCode(pinCodeString);
                    handleClose();
                    break;
                case 'LOGIN':
                    setPinCodeRequestedAtLogin(true);
                    await loginPinCode(pinCodeString);
                    handleClose();
                    break;
                default:
                    createPinFirstCode.value = '';
                    break;
            }
        } catch (rawError) {
            const originalError = convertToBaseError(rawError);
            if (originalError.code.equals(AccessDeniedRemoteApiExceptionCode.ACCESS_DENIED)) router.replace({
                name: RouteName.LOGIN
            });
        }
    }
    function onChange() {
        if (error.value) setError('');
    }
    function onCountDownClick(type) {
        switch(type){
            case 'ENTER_PIN_CODE':
                setStepWithLS(blockedOperation.value);
                break;
            case 'RESET_PIN_CODE':
                setStepWithLS(null);
                setTemporarySession();
                router.push({
                    name: restoreRoute.value
                });
                break;
            default:
                break;
        }
        blockedOperation.value = null;
    }
    function setTemporarySession() {
        setIsTemporarySession(true);
        setLoggedIn(true);
    }
    function onForgot() {
        setTemporarySession();
        setStepWithLS(null);
        "1";
        router.push({
            name: restoreRoute.value
        });
    }
    function closeCompleteRegistrationFields() {
        setShowCompleteRegistrationFields(false);
    }
    function onComponentCreated() {
        setError('');
        if (step.value) handleStep();
        else router.closeModal();
    }
    function onComponentUnmounted() {
        if (modalId.value) {
            closeDialog(modalId.value);
            modalId.value = '';
        }
        pinCodeStore.setStatusIgnored(false);
    }
    watch(()=>step.value, ()=>handleStep());
    return {
        bannedUntil,
        createPinFirstCode,
        enableTweakError,
        error,
        loading,
        pinCodeWrongAttemptsLimit,
        pinCode,
        showCompleteRegistrationFields,
        step,
        closeCompleteRegistrationFields,
        handleCloseWithCleanData,
        onChange,
        onComponentCreated,
        onComponentUnmounted,
        onCountDownClick,
        onForgot,
        onSubmit
    };
}
