import RouteName from '@leon-hub/routing-config';
import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
const caption = '{{$t(\'WEB2_PIN_RESTORE_TITLE\')}}';
export class PinCodeModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this
    addRoutes(router) {
        router.addModalRoute({
            name: RouteName.RESTORE_PIN,
            path: '/pin/restore',
            access: CustomerRouteAccessRole.AUTHORIZED,
            modalPreset: ModalDesktopPreset.SmallDesktopModal,
            prefetch: ()=>import('web/src/modules/restore-password/prefetch/RestoreMainPagePrefetch'),
            component: ()=>import('web/src/modules/pin-code/pages/RestorePinMainRoutePage/RestorePinMainRoutePage.vue'),
            topBar: ()=>import('web/src/modules/restore-password/components/RestorePasswordTopBarRouteComponent/RestorePasswordTopBarRouteComponent.vue'),
            topBarProps: {
                caption,
                isPinCode: true
            }
        });
        router.addProfileRoute({
            name: RouteName.RESTORE_PIN_PROFILE,
            path: '/profile/settings/restore-pin-profile',
            prefetch: ()=>import('web/src/modules/restore-password/prefetch/RestoreMainPagePrefetch'),
            component: ()=>import('web/src/modules/pin-code/pages/RestorePinMainRoutePage/RestorePinMainRoutePage.vue'),
            topBar: ()=>import('web/src/modules/restore-password/components/RestorePasswordTopBarRouteComponent/RestorePasswordTopBarRouteComponent.vue'),
            props: {
                isInProfile: true
            },
            topBarProps: {
                caption,
                isPinCode: true,
                isInProfile: true
            }
        });
        router.addModalRoute({
            name: RouteName.PIN_RESET,
            path: '/reset-pin-code/:token',
            modalPreset: ModalDesktopPreset.SmallDesktopModal,
            component: ()=>import('web/src/modules/restore-password/components/ResetPinRoutePage/ResetPinRoutePage.vue'),
            prefetch: ()=>import('web/src/modules/restore-password/prefetch/ResetPinRoutePagePrefetch'),
            title: '{{$t(\'WEB2_PIN_RESTORE_TITLE\')}}',
            props: true
        });
        router.addModalRoute({
            name: RouteName.PIN_CODE,
            path: '/pin-code',
            modalPreset: ModalDesktopPreset.SmallDesktopModal,
            component: ()=>import('web/src/modules/pin-code/components/PinCodeController/PinCodeController.vue'),
            // eslint-disable-next-line max-len
            topBar: ()=>import('web/src/modules/pin-code/layouts/PinCodeTopBarRouteComponent/PinCodeTopBarRouteComponent.vue')
        });
        router.addProfileRoute({
            name: RouteName.PIN_CODE_PROFILE,
            path: '/pin-code-profile',
            component: ()=>import('web/src/modules/pin-code/components/PinCodeController/PinCodeController.vue'),
            // eslint-disable-next-line max-len
            topBar: ()=>import('web/src/modules/pin-code/layouts/PinCodeTopBarRouteComponent/PinCodeTopBarRouteComponent.vue')
        });
    }
}
