import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { CustomerConfig } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { IconName } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useAuthStore } from 'web/src/modules/auth/store';
import { useUserStore } from 'web/src/modules/user/store';
import { useResponsibleGamblingStore } from 'web/src/modules/responsible-gambling/store';
import useWithdrawalStore from 'web/src/modules/payments/submodules/withdrawals/store/useWithdrawalStore';
import usePinCodeStore from '../../../store/usePinCodeStore';
export default function usePinCodeTopBarRouteComponent() {
    const { $translate } = useI18n();
    const router = useRouter();
    const pinCodeStore = usePinCodeStore();
    const { isLoggedIn } = useAuthStore();
    const { setAdditionalPropsUser } = useUserStore();
    const { setDepositLimitsData, setSelfExclusionId } = useResponsibleGamblingStore();
    const { setRouteFrom, setStepWithLS, setPinCodeModalCounter } = pinCodeStore;
    const withdrawalStore = useWithdrawalStore();
    const pinCodeStep = toRef(pinCodeStore, 'step');
    const pinCodePrevStep = toRef(pinCodeStore, 'prevStep');
    const showCompleteRegistrationFields = toRef(pinCodeStore, 'showCompleteRegistrationFields');
    const pinCodeModalCounter = toRef(pinCodeStore, 'pinCodeModalCounter');
    const routeFrom = toRef(pinCodeStore, 'routeFrom');
    const prefixIsHidden = ref(true);
    const prefixIcon = ref(IconName.CROSS);
    const PIN_CODE_CREATE_STEPS = [
        'MODAL_CREATE',
        'CREATE',
        'CREATE_CONFIRM'
    ];
    const title = computed(()=>{
        if (showCompleteRegistrationFields.value) return $translate('WEB2_PIN_CODE_REG_COMPLETION_TITLE').value;
        switch(pinCodeStep.value){
            case 'CREATE':
            case 'UPDATE':
                return $translate('WEB2_ENTER_NEW_PIN_CODE').value;
            case 'CREATE_CONFIRM':
            case 'UPDATE_CONFIRM':
                return $translate('WEB2_CONFIRM_PIN_CODE').value;
            case 'VERIFY':
            case 'SUCCESS_VERIFY':
            case 'DELETE':
            case 'LOGIN':
            case 'COUNTDOWN':
                return $translate('WEB2_PIN_CODE_ENTER_PIN_CODE').value;
            case 'UPDATE_PREV':
                return $translate('WEB2_ENTER_OLD_PIN_CODE').value;
            default:
                return '';
        }
    });
    function handleClose() {
        const hasPrevStep = pinCodePrevStep.value && PIN_CODE_CREATE_STEPS.includes(pinCodePrevStep.value);
        if (isLoggedIn && hasPrevStep && !pinCodeStep.value) {
            setPinCodeModalCounter(pinCodeModalCounter.value + 1);
            setAdditionalPropsUser({
                value: {
                    value: pinCodeModalCounter.value
                },
                config: CustomerConfig.CREATE_PIN_CODE_MODAL_COUNTER
            });
        }
        if (routeFrom.value) {
            setDepositLimitsData(null);
            setSelfExclusionId(null);
            withdrawalStore.setSubmitData(null);
            router.replace(routeFrom.value);
            setRouteFrom(null);
        } else if (isLoggedIn) router.back();
        else router.replace({
            name: RouteName.HOME
        });
        setStepWithLS(null);
        return true;
    }
    function handleOnMount() {}
    handleOnMount();
    return {
        title,
        handleClose,
        prefixIsHidden,
        prefixIcon
    };
}
